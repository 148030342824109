body{
  background-image: url("./image/bg.webp");
  background-attachment: fixed;
  background-size: 100% 100%;
}

.app-header{
  display: flex;
  background-color: rgb(9, 131, 168);
  justify-content: space-between;
  padding: 0 50px;
}

.wallert-btn{
  margin-top: 15px;
}
.connect-btn{
  padding: 10px 15px;
  background-color: rgb(196, 227, 255);
  cursor: pointer;
  border: 2px solid rgb(3, 3, 2);
  color: rgb(0, 0, 0);
  text-decoration: none;
}
.location{
  display: flex;
  padding: 10px;
  gap: 10px;
}

.location h4{ 
  padding-top: 13px;
  color: #180B3D;
  font-weight: bold;
  gap: 10px;
}

.location h4 a{ 
 text-decoration: none;
 color: #180B3D;
}

.weather-box-body{
  color: #424242;
  margin-top: 20px;
  background-color: rgba(130, 204, 223, 0.815);
  box-shadow: 10px 10px 34px 0px rgba(0,0,0,0.15);
  padding: 20px 10px 10px 10px;
  border-radius: 5px;
  border-left: 3px solid rgb(235, 65, 65);
  margin-bottom: 30px;
}

.weather-box-body:hover{
  color: #000000;
  background-color: rgba(85, 174, 199, 0.815);
  box-shadow: 10px 10px 34px 0px rgba(0,0,0,0.35);
  -webkit-box-shadow: 10px 10px 34px 0px rgba(0,0,0,0.35);
  -moz-box-shadow: 10px 10px 34px 0px rgba(0,0,0,0.35);
}

.box-header{
  display: flex;
  justify-content: space-between; 
}

.details-box{
  display: grid;
  margin-top: 30px;
  padding-left: 10px;
  padding-right: 10px;
}
.sun-icon{
  text-align: center;
}
.currentTemp{
  display: flex;
  justify-content: space-between;
}

.temp{
  padding: 10px;
}

hr{
  display: block;
  height: 2px;
  border: 0;
  border-top: 2px solid rgb(0, 0, 0);
  margin: 1em 0;
  padding: 0px;
}


hr2{
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid rgb(151, 147, 147);
  margin: 1em 0;
  padding: 0px;
}

.wind-details{
  display: flex;
  justify-content: space-between;
}

.cloud{
  margin-right: 10px;
}

.today-and-tomorrow{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.humidity{
  display: flex;
  justify-content: space-between;
}

.today{
  text-align: center;
  padding: 20px;
  height: 300px;
  width: 500px;
}

.tomorrow{
  text-align: center;
  padding: 20px;
  height: 300px;
  width: 500px;
}

.socialLinks{
  display: flex;
  justify-content: center;
  gap:20px
}
a{
  cursor: pointer;
}
.loader{
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}